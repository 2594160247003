<template>
  <div id="VideoCenter">
    <div id="VideoCenter_Cover">
      <img
        :src="GetFullPath(video_center_cover)"
        :alt="video_center_cover_alt"
      />
    </div>
    <div class="video_dialog" v-if="dialog">
      <div class="card">
        <div v-html="video_content"></div>
        <button @click="dialog = false">關閉</button>
      </div>
    </div>
    <div class="row">
      <div class="video_list">
        <template v-if="videos.length <= 0">
          <a class="card ssc" v-for="item in 3" :key="`item_${item}`">
            <div class="search-ssc-lg__tag ssc-square"></div>
            <div class="ssc-head-line w-80" style="margin-top: 10px"></div>
          </a>
        </template>
        <template v-else>
          <router-link
            :to="`/video/show/${item.VideoID}`"
            class="card"
            v-for="item in videos"
            :key="item.id"
          >
            <!-- `http://img.youtube.com/vi/${
                item.Link.split('watch?v=')[1]
              }/sddefault.jpg` -->
            <img :src="GetFullPath(item.Image)" />
            <p class="sub_content_font">{{ item.Title }}</p>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'VideoCenter',
  data() {
    return {
      videos: [],
      dialog: false,
      video_content: '',
      video_center_cover: '',
      video_center_cover_alt: '',
      meta_data: null,
    };
  },
  methods: {
    SetVideo(id) {
      this.dialog = true;
      this.video_content = `<iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/${id}"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> `;
    },
    async LoadData() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/column', 'post', {
        Data: ['video_center_cover', 'video_center_cover_alt'],
      });
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.video_center_cover = response.video_center_cover.Image;
        this.video_center_cover_alt = response.video_center_cover_alt.Content;
        this.LoadVideo();
      }
    },
    async LoadVideo() {
      this.$store.commit('SetLoading', true);
      let response = await this.GetData('/video');
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.videos = response;
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
  },
  created() {
    this.LoadData();
    this.meta_data = GetMetaData(
      '影音專區',
      '居家五金用品、專業五金工具，商品使用說明影片、開箱介紹影片都在宏信五金行！',
      ''
    );
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
